import axios from "axios";
import React, { useState, useEffect } from "react";
import { Button, Dropdown, Pagination } from "react-bootstrap";
import { Base_Url } from "../../../misc";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { AiFillDelete, AiFillEdit, AiFillEye } from "react-icons/ai";
import MyPagination from "../Pagination/MyPagination";
import PageTitle from "../../layouts/PageTitle";


export const PendingSeller = () => {
  const [sellerData, setSellerData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [sellerApprovalFilter, setSellerApprovalFilter] = useState("Pending");
  const [currentPage, setCurrentPage] = useState(1);
  const [PAGE_SIZE, setPAGE_SIZE] = useState(20);
  const [dropValue, setDropValue] = useState("Order by");
  const [filter, setfilter] = useState("filter by");
  const [apiPagination, setApiPagination] = useState("Sort by");


  useEffect(() => {
    axios
      .get(`${Base_Url}/get-dis-approved-seller`)
      .then((res) => {
        setSellerData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  const handleDelete = (id) => {
    axios
      .delete(`${Base_Url}/delete-become-seller/${id}`)
      .then((res) => {
        // console.log(res.data.data.deletedCount);
        if (res.data.data.deletedCount === 1) {
          const updateData = sellerData.filter((r) => r._id !== id);
          setSellerData(updateData);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        } else {
          Swal.fire({
            icon: "warning",
            title: "Something Went Wrong. Try Again",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const del = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(_id);
      }
    });
  };


  const handleApproved = (id, userid) => {
    // console.log("Bhijontry", id, userid);
    axios
      .post(`${Base_Url}/update-become-seller/${id}`, {
        sellerApproval: "Approved",
        status: true,
      })
      .then((res) => {
        // console.log(res.data.data.modifiedCount);
        if (res.data.data.modifiedCount > 0) {
          const updateData = sellerData.filter((r) => r._id !== id);
          setSellerData(updateData);
          Swal.fire({
            icon: "success",
            title: "Seller Approved Successfully!!",
            showConfirmButton: false,
            timer: 1000,
          });
        } else
          Swal.fire({
            icon: "warning",
            title: "Something Went Wrong. Try Again",
            showConfirmButton: false,
            timer: 150,
          });
      })
      .catch((err) => {
        console.log(err);
      });


    axios
      .post(`${Base_Url}/update-users/${userid}`, {
        sellerStatus: "ACCEPTED",
      })
      .then((res) => {
        // console.log(res.data.data.modifiedCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const sortItemsByDate = () => {
    console.log("Inside function");
    const sortedItems = [...filteredData].sort((a, b) => {
      let aDate = new Date(a.createdDate);
      let bDate = new Date(b.createdDate);
      console.log(aDate);
      console.log(bDate);
      return bDate - aDate;
    });
    console.log(sortedItems);
    setFilteredData(sortedItems);
  };
  const sortItemsByDate2 = () => {
    console.log("Inside function");
    const sortedItems = [...filteredData].sort((a, b) => {
      let aDate = new Date(a.createdDate);
      let bDate = new Date(b.createdDate);
      console.log(aDate);
      console.log(bDate);
      return aDate - bDate;
    });
    console.log(sortedItems);
    setFilteredData(sortedItems);
  };


  // Filtering Logic
  useEffect(() => {
    setCurrentPage(1);
    let filtered = sellerData.filter((item) => {
      const kitchenName = item?.kitchenName?.toLowerCase() || "";
      const userFullName = item?.sellerID?.userFullName?.toLowerCase() || "";
      const userMobileNo = item?.sellerID?.userMobileNo || "";
      const email = item?.sellerID?.email?.toLowerCase() || "";


      return (
        kitchenName.includes(searchTerm.toLowerCase()) ||
        userFullName.includes(searchTerm.toLowerCase()) ||
        userMobileNo.includes(searchTerm) ||
        email.includes(searchTerm.toLowerCase())
      );
    });


    // Check if there are no matching results and searchTerm is not empty
    if (searchTerm.trim() !== "" && filtered.length === 0) {
      filtered = [];
    }


    setFilteredData(filtered);
  }, [searchTerm, sellerData]);


  // Pagination Logic
  const indexOfLastItem = currentPage * PAGE_SIZE;
  const indexOfFirstItem = indexOfLastItem - PAGE_SIZE;
  const currentItems = (
    filteredData.length > 0 ? filteredData : sellerData
  ).slice(indexOfFirstItem, indexOfLastItem);


  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(
    (filteredData.length > 0 ? filteredData.length : sellerData.length) /
      PAGE_SIZE
  );


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  // Add an effect to update window width on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };


    window.addEventListener("resize", handleResize);


    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <div>
      <PageTitle activeMenu="Pending Approval" motherMenu="Pending Approval" />
      {/* Responsive */}
      {windowWidth <= 750 && (
        <div
          className="mb-4 align-items-center border p-4 rounded bg-black "
          style={{ backgroundColor: "white" }}
        >
          <div
            className="mt-2 mb-2 pl-2 input-group search-area2 w-100"
            // style={{ width: "100%" }}
          >
            <span className="input-group-text p-0">
              <Link to={"#"}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                    fill="#FC8019"
                  />
                </svg>
              </Link>
            </span>
            <input
              type="text"
              className="form-control p-0"
              placeholder="Filter Name, Phone, Kitchen"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <Dropdown
            className="notification-select"
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            <Dropdown.Toggle
              as="div"
              className="form-control default-select border me-sm-3 m-0 w-auto i-false"
            >
              Sort By Date{" "}
              <i className="fas fa-chevron-down notification-drop-select"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => sortItemsByDate()}
                eventKey="Activity"
              >
                Latest Date
              </Dropdown.Item>


              <Dropdown.Item
                onClick={() => sortItemsByDate2()}
                eventKey="Activity"
              >
                Oldest Date
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>


          {/*  Sort by */}
          {/* <Dropdown
            className="notification-select"
            style={{ marginTop: "5px", marginBottom: "5px", marginLeft: "1px" }}
          >
            <Dropdown.Toggle
              as="div"
              className="form-control default-select border me-sm-3 m-0 w-auto i-false"
            >
              {apiPagination}{" "}
              <i className="fas fa-chevron-down notification-drop-select"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setApiPagination("1-50")}
                eventKey="Activity"
              >
                1-50
              </Dropdown.Item>


              <Dropdown.Item
                onClick={() => setApiPagination("51-100")}
                eventKey="Activity"
              >
                51-100
              </Dropdown.Item>


              <Dropdown.Item
                onClick={() => setApiPagination("101-150")}
                eventKey="Activity"
              >
                101-150
              </Dropdown.Item>


              <Dropdown.Item
                onClick={() => setApiPagination("151-200")}
                eventKey="Activity"
              >
                151-200
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>


          <Dropdown
            className="notification-select"
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            <Dropdown.Toggle
              as="div"
              className="form-control default-select border me-sm-3 m-0 w-auto i-false"
            >
              <i
                className="fa-solid fa-location-dot mx-2"
                style={{ color: "orange" }}
              />{" "}
              <i className="fas fa-chevron-down notification-drop-select"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                // onClick={() => setfilter("1-50")}
                eventKey="Activity"
              >
                Thana
              </Dropdown.Item>


              <Dropdown.Item
                //   onClick={() => setfilter("51-100")}
                eventKey="Activity"
              >
                Region
              </Dropdown.Item>


              <Dropdown.Item
                // onClick={() => setfilter("101-150")}
                eventKey="Activity"
              >
                City
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          {/* <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => setPAGE_SIZE(e.target.value)}
            className="form-control p-2 m-2"
            style={{ width: "50px" }}
          /> */}


          {/* <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => {
              setPAGE_SIZE(e.target.value);
            }}
            className="form-control p-2 m-2 rounded text-center"
            style={{ width: "50px" }}
          /> */}
        </div>
      )}


      {windowWidth >= 751 && (
        <div
          className="mb-3 d-flex align-items-center border p-4 rounded bg-black"
          style={{ backgroundColor: "white" }}
        >
          <div
            className="input-group search-area2 style-1 w-[75%] border d-flex"
            style={{ marginRight: "15px" }}
          >
            <span className="input-group-text p-0">
              <Link to={"#"}>
                <svg
                  className="me-1"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                    fill="#FC8019"
                  />
                </svg>
              </Link>
            </span>


            <input
              type="text"
              className="form-control p-2 "
              placeholder="Filter by seller kitchen name, email, phone"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>


          <div className="input-group ml-2 pl-2 style-1 w-50">
            {/* Search filter */}
            <Dropdown
              className="notification-select"
              style={{ marginTop: "5px", marginBottom: "5px" }}
            >
              <Dropdown.Toggle
                as="div"
                className="form-control default-select border me-sm-3 m-0 w-auto i-false"
              >
                Sort By Date{" "}
                <i className="fas fa-chevron-down notification-drop-select"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => sortItemsByDate()}
                  eventKey="Activity"
                >
                  Latest Date
                </Dropdown.Item>


                <Dropdown.Item
                  onClick={() => sortItemsByDate2()}
                  eventKey="Activity"
                >
                  Oldest Date
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>


            {/*  Sort by */}
            {/* <Dropdown
              className="notification-select"
              style={{
                marginTop: "5px",
                marginBottom: "5px",
                marginLeft: "1px",
              }}
            >
              <Dropdown.Toggle
                as="div"
                className="form-control default-select border me-sm-3 m-0 w-auto i-false"
              >
                {apiPagination}{" "}
                <i className="fas fa-chevron-down notification-drop-select"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => setApiPagination("1-50")}
                  eventKey="Activity"
                >
                  1-50
                </Dropdown.Item>


                <Dropdown.Item
                  onClick={() => setApiPagination("51-100")}
                  eventKey="Activity"
                >
                  51-100
                </Dropdown.Item>


                <Dropdown.Item
                  onClick={() => setApiPagination("101-150")}
                  eventKey="Activity"
                >
                  101-150
                </Dropdown.Item>


                <Dropdown.Item
                  onClick={() => setApiPagination("151-200")}
                  eventKey="Activity"
                >
                  151-200
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>


            <Dropdown
              className="notification-select"
              style={{ marginTop: "5px", marginBottom: "5px" }}
            >
              <Dropdown.Toggle
                as="div"
                className="form-control default-select border me-sm-3 m-0 w-auto i-false"
              >
                <i
                  className="fa-solid fa-location-dot mx-2"
                  style={{ color: "orange" }}
                />{" "}
                <i className="fas fa-chevron-down notification-drop-select"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  // onClick={() => setfilter("1-50")}
                  eventKey="Activity"
                >
                  Thana
                </Dropdown.Item>


                <Dropdown.Item
                  //   onClick={() => setfilter("51-100")}
                  eventKey="Activity"
                >
                  Region
                </Dropdown.Item>


                <Dropdown.Item
                  // onClick={() => setfilter("101-150")}
                  eventKey="Activity"
                >
                  City
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
            {/* <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => setPAGE_SIZE(e.target.value)}
            className="form-control p-2 m-2"
            style={{ width: "50px" }}
          /> */}


            {/* <input
            type="number"
            placeholder=""
            value={PAGE_SIZE}
            onChange={(e) => {
              setPAGE_SIZE(e.target.value);
            }}
            className="form-control p-2 m-2 rounded text-center"
            style={{ width: "50px" }}
          /> */}
          </div>
        </div>
      )}


      <div
        className="card h-auto "
        //style={{ marginTop: 10, marginLeft: -160, width: "127%" }}
      >
        <div className="card-body p-0">
          <div className="table-responsive">
            {sellerData === undefined ? (
              "Loading..."
            ) : (
              <>
                <table
                  className="table table-list i-table style-1 mb-4 border-0"
                  id="guestTable-all3"
                >
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Name</th>
                      <th>Phone</th>
                      {/* <th>Email</th> */}
                      <th>Address</th>
                      <th>Kitchen Name</th>
                      <th>Seller Approval</th>
                      <th>Action</th>
                      <th className="bg-none">View Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems
                      .filter((i) => i.sellerApproval === "Pending")
                      .map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1 + PAGE_SIZE * (currentPage - 1)}</td>
                            <td>
                              {item?.sellerID && item?.sellerID.userFullName
                                ? item?.sellerID.userFullName
                                : "N/A"}
                            </td>
                            <td>
                              {item?.sellerID && item?.sellerID.userMobileNo
                                ? item?.sellerID.userMobileNo
                                : "N/A"}
                            </td>
                            {/* <td>
                            {item?.sellerID && item?.sellerID.email
                              ? item?.sellerID.email
                              : "N/A"}
                          </td> */}
                            <td>
                              {item?.address
                                ? item?.address?.length > 10
                                  ? `${item?.address.slice(0, 15)}..`
                                  : item?.address
                                : "N/A"}
                            </td>
                            <td>
                              {item?.kitchenName && item?.kitchenName
                                ? item?.kitchenName
                                : "N/A"}
                            </td>
                            <td>
                              {item.sellerApproval === "Approved" && (
                                <p>Approved for sell food</p>
                              )}
                              {item.sellerApproval === "Draft" && (
                                <p>Not fill up 2nd step data</p>
                              )}
                              {item.sellerApproval === "Pending" && (
                                <Button
                                  onClick={() =>
                                    handleApproved(item._id, item?.sellerID._id)
                                  }
                                  className="btn btn-outline-primary"
                                >
                                  Approve
                                </Button>
                              )}
                            </td>
                            <td>
                              <div className="d-flex gap-2">
                                <div
                                  onClick={() => {
                                    del(item._id);
                                  }}
                                  style={{
                                    backgroundColor: "#db2777",
                                    padding: "5px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <AiFillDelete size={18} color="white" />
                                </div>
                              </div>
                            </td>
                            <td>
                              <Link
                                to={`/sellerdetails/${item?._id}`}
                                state={item}
                              >
                                <div
                                  style={{
                                    height: "50px",
                                  }}
                                >
                                  <AiFillEye size={40} color="orange" />
                                </div>
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>


                <MyPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};


export default PendingSeller;